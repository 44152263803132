import React, { useState, useEffect, lazy, Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import bcrypt from 'bcryptjs';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));


const Login = () => {
  const [isVerified, setIsVerified] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    document.title = isVerified ? 'Doji - Gen 1 Demo' : 'Login';
  }, [isVerified]);

  const checkPw = (e) => {
    e.preventDefault();
    const answer = document.getElementById("password").value;
    setIsLoading(true);
    setError(null);

    setTimeout(() => {
      const hashedPassword = '$2y$10$f7wJ9UzqqduP164mLghtJ.A4Ryo1q1yNkx1QH7KAjQPfYvb/qCM6O';
      bcrypt.compare(answer, hashedPassword, (err, result) => {
        if (result) {
          setIsVerified(true);
        } else {
          setError("Sorry, that's not the correct password.");
        }
        setIsLoading(false);
      });
    }, 1000);
  };

  const App = lazy(() => import('./App'));


  return (
    <>
      {isVerified ? (
        <Suspense fallback={<div>Loading...</div>}>
        <App />
      </Suspense>
      ) : (
        <div className="flex flex-col justify-center items-center h-screen bg-gray-100">
          <form onSubmit={checkPw} className="relative mb-4">
            <input
              id="password"
              name="password"
              type="password"
              placeholder="Enter your password"
              className={`w-80 px-4 py-2 pr-12 border rounded-full focus:outline-none focus:ring-2 ${
                error ? "border-red-500 focus:ring-red-500" : "border-gray-300 focus:ring-blue-500"
              }`}
              disabled={isLoading}
            />
            <button
              type="submit"
              className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-white border border-gray-300 rounded-full p-2 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500"
              disabled={isLoading}
            >
              {isLoading ? (
                <svg
                  className="animate-spin h-4 w-4 text-gray-600"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  />
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4 text-gray-600"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M9 5l7 7-7 7"
                  />
                </svg>
              )}
            </button>
          </form>
          {error && <p className="text-red-500 text-sm">{error}</p>}
        </div>
      )}
    </>
  );
};

root.render(
  <React.StrictMode>
    <Login />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

